import {useColorModeValue} from '@chakra-ui/react'


const createMapping = (mode, shadeMap, baseColors) => {
    return Object.assign({}, ...[].concat(...baseColors.map(color => {
        return Object.keys(shadeMap).map(shade => {
            return {
                [`${color}.${shade}`]: mode === 'light' ? `${color}.${shade}` : `${color}.${shadeMap[shade]}`
            }
        })
    })))
}


const shadeMap = {
    50: 900,
    100: 800,
    200: 500,
    300: 500,
    400: 400,
    500: 200,
    600: 200,
    700: 100,
    800: 100,
    900: 50
}

const baseColors = ['whiteAlpha', 'blackAlpha', 'blue', 'green', 'red', 'gray', 'orange', 'pink', 'yellow', 'purple']

let lightTheme = createMapping('light', shadeMap, baseColors)
lightTheme = {
    ...lightTheme, ...{
        'white': 'white',
        'otherWhite.pure': 'otherWhite.pure',
        'otherWhite.bg': 'otherWhite.bg',
        'black': 'black',
        'otherBlack.pure': 'otherBlack.pure',
        'otherBlack.bg': 'otherBlack.bg',
    }
}

let darkTheme = createMapping('dark', shadeMap, baseColors)
darkTheme = {
    ...darkTheme, ...{
        'white': 'black',
        'otherWhite.pure': 'otherBlack.pure',
        'otherWhite.bg': 'otherBlack.bg',
        'black': 'white',
        'otherBlack.pure': 'otherWhite.pure',
        'otherBlack.bg': 'otherWhite.bg',
    }
}

export default function useThemeColors() {
    return useColorModeValue(lightTheme, darkTheme)
}
