export default {
    white: '#fcfdff',
    black: '#1f1f1f',
    otherWhite: {
        pure: '#FFF',
        bg: '#fafafa'
    },
    otherBlack: {
        pure: '#000',
        bg: '#151515'
    },
    blue: {
        accent: '#4E6AFF',
        50: '#e7e9f7',
        100: '#c2c8eb',
        200: '#99a4de',
        300: '#6f81d1',
        400: '#4d66c7',
        500: '#264bbd',
        600: '#1f43b3',
        700: '#1139a7',
        800: '#002f9b',
        900: '#001c88',
    },
    red: {
        accent: '#FF3D49',
        50: '#fce4e9',
        100: '#f9bbc9',
        200: '#f490a5',
        300: '#ee6583',
        400: '#e84769',
        500: '#e23051',
        600: '#d22b4f',
        700: '#bd264c',
        800: '#a92049',
        900: '#851843',
    },
    green: {
        accent: '#47DC87',
        50: '#e6f6e6',
        100: '#c4e9c1',
        200: '#9dda9a',
        300: '#72cd6f',
        400: '#4ec24e',
        500: '#21b627',
        600: '#11a71e',
        700: '#00950e',
        800: '#008400',
        900: '#006500',
    },
    gray: {
        50: '#f5f5f5',
        100: '#d6d6d6',
        200: '#b8b8b8',
        300: '#999999',
        400: '#858585',
        500: '#707070',
        600: '#5c5c5c',
        700: '#474747',
        800: '#333333',
        900: '#1f1f1f',
    },
    orange: {
        50: '#fff2e0',
        100: '#feddb3',
        200: '#fdc882',
        300: '#fcb151',
        400: '#fba02f',
        500: '#fa9118',
        600: '#f58517',
        700: '#ef7516',
        800: '#e86615',
        900: '#de4c14',
    },
    purple: {
        50: '#f2e5f7',
        100: '#dfbeeb',
        200: '#cb93df',
        300: '#b668d2',
        400: '#a747c8',
        500: '#9726bd',
        600: '#8824b7',
        700: '#7420af',
        800: '#611ca7',
        900: '#3c169a',
    },
    pink: {
        50: '#FFEBF6',
        100: '#FFC2E3',
        200: '#FFADD9',
        300: '#FF85C6',
        400: '#FF5CB3',
        500: '#FF2D9D',
        600: '#E00077',
        700: '#E00077',
        800: '#7A0041',
        900: '#3D0021',
    },
    yellow: {
        50: '#fffae6',
        100: '#fff1c1',
        200: '#ffea99',
        300: '#fde172',
        400: '#fada53',
        500: '#f7d434',
        600: '#e7c330',
        700: '#d2ad2a',
        800: '#bd9726',
        900: '#9a721d',
    },
}
