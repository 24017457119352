import 'focus-visible/dist/focus-visible'
import '@fontsource/archivo/400.css'
import '@fontsource/archivo/400-italic.css'
import '@fontsource/archivo/600.css'
import '@fontsource/archivo/600-italic.css'
import '@fontsource/archivo/700.css'
import '@fontsource/archivo/700-italic.css'

import { Box, ChakraProvider } from '@chakra-ui/react'

import AppContainer from '../components/common/AppContainer'
import GlobalLoader from '../components/common/GlobalLoader'
import MetaTags from '../components/common/MetaTags'
import theme from '../theme/main'

function YRApp({ Component, pageProps }) {
    return (
        <ChakraProvider theme={theme}>
            <AppContainer>
                <MetaTags/>
                <GlobalLoader>
                    <Box id={'YRContent'} pb={0}>
                        <Component {...pageProps} />
                    </Box>
                </GlobalLoader>
            </AppContainer>
        </ChakraProvider>
    )
}

export default YRApp
