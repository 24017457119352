import React from 'react';
import {Center} from '@chakra-ui/layout';
import {useBreakpoint} from '@chakra-ui/media-query';
import {PropagateLoader} from "react-spinners";
import useThemeColors from "../../hooks/themeColor";

function GlobalLoader({children}) {
    const breakpoint = useBreakpoint();
    const themeColor = useThemeColors()

  if (breakpoint !== undefined) {
    return <>{children}</>;
  }
  return (
      <Center height="100vh" bg={themeColor['otherWhite.bg']}>
          <PropagateLoader speedMultiplier={2} size={20} loading={true} color={themeColor['blue.300']}/>
      </Center>
  );
}

export default GlobalLoader;
