import {destroyCookie, parseCookies, setCookie} from 'nookies'

import Router from 'next/router'

const AUTH_TOKEN_COOKIE_NAME = 'authorizationToken'
const BROKER_COOKIE_NAME = 'broker'

export const getAuthTokenFromCookie = (ctx = null) => {
    return parseCookies(ctx)[AUTH_TOKEN_COOKIE_NAME]
}

export const getBrokerFromCookie = (ctx = null) => {
    return parseCookies(ctx)[BROKER_COOKIE_NAME]
}

export const setAuthTokenCookie = (ctx = null, value) => {
    setCookie(ctx, AUTH_TOKEN_COOKIE_NAME, value, { secure: true, path: '/' })
}

export const setBrokerCookie = (ctx = null) => {
    const host = ctx.req.headers.host.toLowerCase()
    let broker = undefined
    if (host.includes('localhost') || host.includes('rocks')) {
        // broker = 'rocks'
    } else if (host.includes('iifl') || host.includes('indiainfoline')) {
        broker = 'iifl'
    } else if (host.includes('5paisa')) {
        broker = '5paisa'
    } else if (host.includes('edelweiss')) {
        broker = 'edelweiss'
    } else if (host.includes('aliceblue')) {
        broker = 'aliceblue'
    }
    broker ? setCookie(ctx, BROKER_COOKIE_NAME, broker, { path: '/', secure: true }) : null
    return broker
}

export const isLoggedIn = (ctx = null) => {
    return !!getAuthTokenFromCookie(ctx)
}

export const triggerLogout = (ctx = null, redirectTo = '/login') => {
    destroyCookie(ctx, AUTH_TOKEN_COOKIE_NAME, { path: '/' })
    checkAndTriggerLogout(ctx, redirectTo)
}

export const triggerLogin = (ctx = null, authToken, redirectTo) => {
    setAuthTokenCookie(ctx, authToken)
    checkAndTriggerLogin(ctx, redirectTo)
}

export const checkAndTriggerLogout = (ctx = null, redirectTo = '/login') => {
    if (!isLoggedIn(ctx)) {
        if (ctx) {
            ctx.res.writeHead(302, {
                Location: redirectTo,
            })
            ctx.res.end()
        } else {
            Router.push(redirectTo)
        }
    }
}

export const checkAndTriggerLogin = (ctx = null, redirectTo = '/dashboard') => {
    if (isLoggedIn(ctx)) {
        if (ctx) {
            ctx.res.writeHead(302, {
                Location: redirectTo,
            })
            ctx.res.end()
        } else {
            Router.push(redirectTo)
        }
    }
}
