import {SWRConfig} from 'swr'
import {useToast} from "@chakra-ui/react";
import {triggerLogout} from "../../utils/login";

const AppContainer = ({children}) => {

    const toast = useToast()
    const toastId = "logout"

    return (
        <SWRConfig
            value={{
                onError: (error, key) => {
                    console.log('SWR_CONFIG_ERROR', error.info)
                    if (error.status === 403) {
                        if (!toast.isActive(toastId)) {
                            toast({
                                id: 'logout',
                                title: 'You have been logged out',
                                status: 'info',
                            })
                        }
                    }
                    triggerLogout()
                }
            }}
        >
            {children}
        </SWRConfig>
    )
}

export default AppContainer