export default {
    global: (props) => ({
        html: {
            scrollBehavior: 'smooth',
        },
        body: {
            bg: props.colorMode === 'dark' ? 'otherBlack.bg' : 'otherWhite.bg',
            color: props.colorMode === 'dark' ? 'white' : 'black',
            lineHeight: 'normal',
            scrollBehavior: 'smooth',
        },
    }),
}
